import React, { useRef, useEffect } from 'react';

const Music = () => {
  const audioRef = useRef(null);
  const songTitle = 'DJ baby dont go x river flows (128 kbps).mp3';

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    };

    const handleClick = () => {
      playAudio();
      document.removeEventListener('click', handleClick); // Hapus event listener setelah pertama kali diklik
    };

    document.addEventListener('click', handleClick); // Tambahkan event listener

    return () => {
      audioRef.current.pause();
      document.removeEventListener('click', handleClick); // Hapus event listener saat komponen dibongkar
    };
  }, []);

  return (
    <div className="mt-5 music-player p-4 border border-gray-300 rounded-full max-w-md mx-auto flex items-center">
      <audio 
        ref={audioRef} 
        src={require('../assets/djriver.mp3')} 
        preload="metadata" 
        loop 
      />
      
      <img 
        src={require('../assets/spotify.png')} 
        alt="Spotify"
        className="w-12 h-12 rounded-lg mr-3 animate-spin" 
      />

      <div className="flex-1">
        <div className="flex items-center justify-center mb-1">
          <h2 className="text-lg font-bold text-center font-poppins mr-3">Auto Listening</h2> 
        </div>
        <div className="text-center text-xs mr-3 text-gray-500 font-poppins">{songTitle}</div> 
      </div>
    </div>
  );
};

export default Music;
