// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GithubAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmtOojXuY3SQy5DzerZfNhjEz75Lb7FPY",
  authDomain: "portfolio-guestbook-2a5d5.firebaseapp.com",
  projectId: "portfolio-guestbook-2a5d5",
  storageBucket: "portfolio-guestbook-2a5d5.appspot.com",
  messagingSenderId: "657050397576",
  appId: "1:657050397576:web:88ff459af8e569ba8f683d",
  measurementId: "G-0NWNN12YP0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const githubProvider = new GithubAuthProvider();
const firestore = getFirestore(app);

export { auth, githubProvider, signInWithPopup, signOut, firestore };
