import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ProjectSection = () => {
  const [projects, setProjects] = useState([]);

  const githubUsername = 'Mutiaacode';

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`https://api.github.com/users/${githubUsername}/repos`);
        const data = await response.json();

        const filteredProjects = data
          .filter(repo => !repo.fork)
          .slice(0, 4)
          .map(repo => ({
            title: repo.name,
            description: repo.description || 'Deskripsi tidak tersedia.',
            link: repo.html_url,
          }));
        setProjects(filteredProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [githubUsername]);

  return (
    <section className="my-12 font-poppins">
      <h2 className="text-xl sm:text-2xl font-bold text-white text-left mb-4">Repositories 📑</h2>
      <div className="rounded-lg">
        {projects.map((project, index) => (
          <motion.div
            key={index}
            className={`text-white px-4 py-3 border ${index === 0 ? 'rounded-t-lg' : ''} ${index === projects.length - 1 ? 'rounded-b-xl' : ''}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center justify-between">
              <h3 className="text-lg sm:text-xl font-semibold">{project.title}</h3>
              <div className="flex space-x-2">
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"  
                    className="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  >
                    <path d="M12 0C5.372 0 0 5.373 0 12c0 5.303 3.438 9.8 8.207 11.388.6.111.793-.259.793-.577 0-.284-.011-1.233-.017-2.234-3.338.726-4.043-1.61-4.043-1.61-.546-1.385-1.333-1.756-1.333-1.756-1.089-.743.083-.728.083-.728 1.205.085 1.838 1.237 1.838 1.237 1.07 1.831 2.807 1.303 3.49.996.108-.775.42-1.303.762-1.604-2.665-.303-5.466-1.33-5.466-5.917 0-1.308.467-2.376 1.236-3.22-.124-.303-.536-1.523.117-3.174 0 0 1.008-.322 3.303 1.23A11.534 11.534 0 0112 5.834c1.01.005 2.024.136 3.003.396 2.295-1.552 3.303-1.23 3.303-1.23.653 1.651.241 2.871.118 3.174.77.844 1.236 1.912 1.236 3.22 0 4.601-2.804 5.609-5.472 5.905.432.373.818 1.104.818 2.223 0 1.607-.014 2.898-.017 3.29 0 .319.188.692.797.574C20.565 21.796 24 17.299 24 12c0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>

              </div>
            </div>
            <p className="text-gray-400 mt-1 text-sm sm:text-base">{project.description}</p>
          </motion.div>
        ))}
        <div className="text-center mt-6">
          <a href={`https://github.com/${githubUsername}?tab=repositories`} className="text-blue-400 hover:underline text-sm sm:text-base">View all my Open Source projects</a>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
