import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { firestore, auth, githubProvider, signInWithPopup, signOut } from './firebase';

const Guestbook = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'guestbook'));
      const fetchedComments = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : null,
      }));
      setComments(fetchedComments);
    };
    fetchComments();
  }, []);

  const handleAddComment = async () => {
    if (newComment.trim() === '' || !user) return;

    const timestamp = Timestamp.now();
    await addDoc(collection(firestore, 'guestbook'), {
      text: newComment,
      user: user.displayName,
      timestamp,
    });
    setComments(prevComments => [
      ...prevComments,
      { text: newComment, user: user.displayName, timestamp: timestamp.toDate() },
    ]);
    setNewComment('');
  };

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, githubProvider);
      setUser(result.user);
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="mt-8 p-8 text-white rounded-lg font-sans">
      <h1 className="text-2xl font-bold text-left mb-4 font-poppins">Visitor's Log 📚</h1>
      <p className="mb-6 text-gray-400 font-poppins">
        Hello! I’m not sure what to put in this description, but you probably know what a Visitor Log is.
        Feel free to leave any messages here - comments, suggestions, or just a friendly greeting.
      </p>

      {!user ? (
        <button 
          onClick={handleSignIn} 
          className="w-full py-2 px-4 bg-[#5865F2] hover:bg-[#4A5AC7] text-white font-semibold rounded-lg flex items-center justify-center transition duration-300">
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M12 0C5.372 0 0 5.372 0 12c0 5.303 3.438 9.8 8.207 11.385.6.11.793-.26.793-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.756-1.333-1.756-1.09-.745.082-.73.082-.73 1.204.085 1.838 1.236 1.838 1.236 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.76-1.606-2.665-.303-5.467-1.332-5.467-5.932 0-1.31.467-2.38 1.235-3.22-.124-.303-.535-1.524.118-3.176 0 0 1.007-.322 3.3 1.23.957-.266 1.984-.399 3.005-.404 1.02.005 2.047.138 3.005.404 2.29-1.552 3.297-1.23 3.297-1.23.654 1.652.244 2.873.12 3.176.77.84 1.233 1.91 1.233 3.22 0 4.61-2.805 5.624-5.475 5.921.429.37.812 1.102.812 2.222 0 1.605-.014 2.896-.014 3.286 0 .319.19.694.8.576C20.565 21.798 24 17.3 24 12c0-6.628-5.372-12-12-12z" clipRule="evenodd" />
          </svg>
          Sign in with GitHub
        </button>
      ) : (
        <div>
          <button 
            onClick={handleSignOut} 
            className="w-full py-2 px-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg transition duration-300">
            Sign out
          </button>
          <h3 className="text-xl font-bold mt-4">Leave a Comment, {user.displayName}</h3>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            rows="4"
            className="w-full mt-2 p-3 bg-[#1F2230] rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your comment here..."
          />
          <button
            onClick={handleAddComment}
            className="w-full py-2 px-4 bg-[#5865F2] hover:bg-[#4A5AC7] text-white font-semibold rounded-lg mt-2 transition duration-300"
          >
            Add Comment
          </button>
        </div>
      )}

      {/* Pembatas Komentar */}
      <hr className="border-t border-gray-600 my-6" />

      <div className="mt-6 space-y-4">
        {comments.map((comment, index) => (
          <div key={index} className="bg-transparent pt-4">
            <p className="text-lg text-white font-poppins">{comment.text}</p>
            <span className="text-sm text-gray-500 block mt-2 font-poppins">
              — {comment.user} • {comment.timestamp ? comment.timestamp.toLocaleDateString() : "Unknown Date"} at {comment.timestamp ? comment.timestamp.toLocaleTimeString() : "Unknown Time"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Guestbook;
