import React from 'react';
import { motion } from 'framer-motion';
import Guestbook from '../firebase/Guestbook';
import ProjectSection from '../components/ProjectSection';
import VisitorCounter from '../firebase/VisitorCounter';
import Navbar from '../components/Navbar';
import rocketImage from '../assets/rocket.png'; 
import Music from '../components/Music';

const Portfolio = () => {
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="bg-gray-800 text-white min-h-screen p-5 font-poppins">
      <Navbar />
      <div className="mt-5 max-w-3xl mx-auto px-4">
        <div className="flex items-center space-x-4">
          <motion.img
            src="aku2.jpeg"
            alt="Profile"
            className="w-20 h-20 rounded-full"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
          <div>
            <motion.h1
              className="text-lg sm:text-xl font-bold font-poppins flex items-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Mutia Pegi Intanswari
              <i className="fas fa-check-circle text-blue-500 ml-2"></i>
            </motion.h1>

            <motion.p
              className="text-sm sm:text-base text-gray-400"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Indonesia - Metro Lampung
            </motion.p>
            <motion.a
              href="https://mutiadev.site"
              className="text-blue-500 text-xs sm:text-sm"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              mutiadev.site
            </motion.a>
          </div>
        </div>

        <Music />

        <section id="about" className="mt-10">
          <motion.h2
            className="text-xl sm:text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            About Me
          </motion.h2>
          <motion.p
            className="mt-2 text-sm sm:text-base text-gray-600 font-semibold font-poppins"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Hi, I'm Mutia Pegi Intanswari, a Software Engineering student at SMK NEGERI 3 METRO. I love coding and creating impactful tech solutions. Whether it's building web apps, managing databases, or designing user-friendly interfaces, I'm all in for delivering quality work.
          </motion.p>
        </section>

        <section id="experience" className="mt-10">
          <motion.h2
            className="text-xl sm:text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Freelance Experience 📑
          </motion.h2>

          <motion.div
            className="mt-6 space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-400">Arcane Project Technology 2024</h3>
              <p className='mt-1 text-gray-600 text-sm sm:text-base font-semibold'>As the owner of Arcane Project Technology, I specialize in web development and graphic design. My role involves creating visually stunning and highly functional websites that enhance user experience and engagement. With a deep understanding of both front-end and back-end technologies, I deliver customized solutions that meet the specific needs of clients.</p>
              <p className="text-xs mt-1 text-gray-400">React • Laravel • Databases</p>
            </div>

            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-400">Bot Developer 2021-2023</h3>
              <p className='mt-1 text-gray-600 text-sm sm:text-base font-semibold'>I develop advanced bots for WhatsApp that streamline various user needs, including e-commerce functionalities and interactive gaming experiences. My bots are designed to simplify tasks and provide a seamless user experience through automation and integration with external services.</p>
              <p className="text-xs mt-1 text-gray-400">JavaScript • NodeJS • Scraper • API</p>
            </div>

            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-400">Hosting Provider 2022-2023</h3>
              <p className='mt-1 text-gray-600 text-sm sm:text-base font-semibold'>I offer high-quality web hosting services for game servers and websites, providing robust and reliable hosting solutions at affordable prices. My services are tailored to ensure optimal performance and uptime, catering to the diverse needs of clients.</p>
              <p className="text-xs mt-1 text-gray-400">Pterodactyl • NodeJs</p>
            </div>

            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-400">Discord Server Creation Service 2023</h3>
              <p className='mt-1 text-gray-600 text-sm sm:text-base font-semibold'>I create and manage sophisticated and aesthetically pleasing Discord servers that align with users' preferences and requirements. Whether it’s for community engagement, gaming, or professional purposes, I ensure that each server is customized to provide the best experience for its members.</p>
              <p className="text-xs mt-1 text-gray-400">Discord • Music</p>
            </div>
          </motion.div>
        </section>

        <section className="mt-10">
          <motion.h2
            className="text-xl sm:text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Achievement 🏆
          </motion.h2>
          <motion.div
            className="text-sm sm:text-base mt-1 text-gray-600 font-semibold"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p> • LKS Web Tech City</p>
            <p> • LKS Web Tech Province</p>
            <p> • LKS Web Tech National</p>
          </motion.div>
        </section>

        <section id="repository">
          <ProjectSection />
        </section>

        <section id="logs" className="mt-10">
          <motion.h3
            className="text-xl sm:text-2xl font-bold font-poppins"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Visitor's Logs
          </motion.h3>
          <VisitorCounter />
          <Guestbook />
        </section>

       
        <section className="mt-10 flex justify-center">
          <motion.span
            className="text-4xl cursor-pointer"
            initial={{ y: 0 }}
            animate={{ y: [0, -15, 0] }} 
            transition={{ duration: 0.5, repeat: Infinity }} 
            onClick={scrollToTop} 
          >
            <img src={rocketImage} alt="Rocket" className="w-10 h-10" />
          </motion.span>
        </section>


        <hr className="border-t border-gray-600 my-5" />

      </div>

      <footer className="text-gray-400 py-4 mt-5">
        <div className="max-w-3xl mx-auto text-center">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            &copy; 2024 Made with ❤️ by Mutia
          </motion.p>
          <motion.div
            className="flex justify-center mt-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <a href="https://github.com/Mutiaacode" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white mx-2">
              <i className="fab fa-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/mutia-pegi-intanswari-184597298/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white mx-2">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://wa.me/6289636535790" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white mx-2">
              <i className="fab fa-whatsapp"></i>
            </a>
          </motion.div>
        </div>
      </footer>
    </div>
  );
};

export default Portfolio;
