import React, { useEffect, useState } from 'react';
import { firestore } from './firebase'; 
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const VisitorCounter = () => {
  const [visitors, setVisitors] = useState(0);

  useEffect(() => {
    const incrementVisitorCount = async () => {
      const visitorDocRef = doc(firestore, "visitors", "counter");

      try {
        const visitorDoc = await getDoc(visitorDocRef);
        if (visitorDoc.exists()) {
          const currentCount = visitorDoc.data().count;
          setVisitors(currentCount + 1);
          await updateDoc(visitorDocRef, { count: currentCount + 1 });
        } else {
          await updateDoc(visitorDocRef, { count: 1 });
          setVisitors(1);
        }
      } catch (error) {
        console.error("Error updating visitor count: ", error);
      }
    };

    incrementVisitorCount();
  }, []);

  return (
    <div className="text-white text-sm mt-5 font-poppins">
      <p>Total visited {visitors}</p>
    </div>
  );
};

export default VisitorCounter;
